import { find } from "../utils";
import React, { useState } from "react";
import { useScale } from "../scale";
import Navbar from "../elements/Navbar";
import Modal, { ModalProps } from "../elements/Modal";
import {useLanguage} from "../lang";


export type PageContentProps = {
    scale: string
    language: string
    mobile: boolean
    oblong: boolean
    setLanguage: (newKey: string) => void
    getString: (key: string) => string
    setModalContent: (newContent: ModalProps | null) => void
}

type PageProps = {
    name: string
    nav?: boolean
    root: (props: PageContentProps) => React.JSX.Element
}

function Page({name, root, nav = false}: Readonly<PageProps>) {
    const scale = useScale();
    const [language, setLanguage, getString] = useLanguage();
    const [modalContent, setModalContent] = useState<ModalProps | null>(null);

    const props = {
        scale: scale,
        language: language,
        mobile: scale.includes('mobile'),
        oblong: scale.includes('oblong'),
        setLanguage: setLanguage,
        getString: getString,
        setModalContent: setModalContent
    };

    return <>
        <link href={find('styles', `${name}.css`)} rel="stylesheet"/>
        {/*<div style={{*/}
        {/*    position: 'absolute',*/}
        {/*    backgroundColor: 'red',*/}
        {/*    width: '100%',*/}
        {/*    top: '0px',*/}
        {/*    fontSize: '20pt',*/}
        {/*    textAlign: 'center',*/}
        {/*    zIndex: '20'*/}
        {/*}}>{`${props.scale} ${name}`}</div>*/}
        {nav ? <>
            {React.createElement(Navbar, props)}
            <div className={`body ${props.scale}`}>
                {modalContent ? React.createElement(Modal, {...props, ...modalContent}) : null}
                {React.createElement(root, props)}
            </div>
        </> : <>
            {modalContent ? React.createElement(Modal, {...props, ...modalContent}) : null}
            {React.createElement(root, props)}
        </>}
    </>;
}

export default Page;