import React from "react";

import { find } from "../../utils";
import {ModalDefProps} from "../../elements/Modal";

const HowToPlayModal = (props: Readonly<ModalDefProps>) => {
    return (<>
        <div className="modal-title playfair">{props.getString('help.title')}</div>

        <div style={{flexGrow: 1}}/>

        <img style={{height: 30}} alt={props.getString('help.classic1_alt')} src={find('assets/cts/icon', 'how-to-1.svg')} />
        <div className="modal-heading akkurat">- {props.getString('help.classic1_title')} -</div>
        <div className="modal-body akkurat">{props.getString('help.classic1')}</div>

        <div style={{flexGrow: 1}}/>

        <img style={{height: 30}} alt={props.getString('help.classic2_alt')} src={find('assets/cts/icon', 'how-to-2.svg')} />
        <div className="modal-heading akkurat">- {props.getString('help.classic2_title')} -</div>
        <div className="modal-body akkurat">{props.getString('help.classic2')}</div>

        <div style={{flexGrow: 1}}/>

        <img style={{height: 30}} alt={props.getString('help.classic3_alt')} src={find('assets/cts/icon', 'how-to-3.svg')} />
        <div className="modal-heading akkurat">- {props.getString('help.classic3_title')} -</div>
        <div className="modal-body akkurat">{props.getString('help.classic3')}</div>

        <div style={{flexGrow: 1}}/>
    </>);
}

export default HowToPlayModal;
