import DynamicButton from "./DynamicButton";
import { CaretDownFilled } from "@ant-design/icons";
import React from "react";
import Button from "./Button";
import {GlobalOutlined} from "@ant-design/icons/lib/icons";

type LanguageButtonProps = {
    mobile: boolean
    showIcon?: boolean
    onClick: () => void
    current: string
}

const LanguageButton = (props: Readonly<LanguageButtonProps>) => {
    return (<Button
        id="language-button"
        className="btn-solid btn-label"
        onClick={props.onClick}
        icon={<GlobalOutlined/>}
        iconRight={<CaretDownFilled/>}
        style={{flexGrow: 1, width: '222px'}}
    >
        {props.current}
    </Button>);
}

export default LanguageButton;