import React, {useState} from "react";
import {Switch} from "antd";
import useLocalStorage from "../local_storage";
import {ModalDefProps} from "../../elements/Modal";
import {LANGUAGE_NAMES, useLanguage} from "../../lang";
import LanguageButton from "../../elements/LanguageButton";
import Popper from "../../elements/Popper";


type Option = {
    title: string
    state: boolean
    disabled?: boolean
    toggle: (newValue: boolean) => void
}

type Section = {
    title: string
    options: Array<Option>
}

const OPTION_SECTIONS = {
    'gameplay': {
        'use_hints': false,
        'allow_movie_series': true,
        // 'allow_tv_series': false,
        'disable_profile': false
    },
    'choose': {
        'use_default': true,
        'use_expanded': false,
        'use_bollywood': false,
        'use_blockbuster': false
    }
}


const OPTION_SELECT_ONE = ['choose'];

const OptionsModal = (props: Readonly<ModalDefProps>) => {
    const [selectLanguage, setSelectLanguage] = useState<boolean>(false);

    const sections: Array<Section> = [];
    for (const [sectionName, options] of Object.entries(OPTION_SECTIONS)) {
        const onlyOne = OPTION_SELECT_ONE.includes(sectionName);

        let selected: number = 0;
        const items: Array<Option> = [];
        for (const [name, defaultValue] of Object.entries(options)) {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const [state, toggle] = useLocalStorage<boolean>(name, defaultValue);
            if (state)
                selected += 1;
            items.push({
                title: props.getString(`options.${name}`),
                state: state,
                toggle: toggle,
                disabled: onlyOne ? (state && selected === 1) : false
            })
        }
        sections.push({
            title: props.getString(`options.${sectionName}`),
            options: items
        });
    }
    //
    // const numSelected = [useStandard, useExpanded, useBollywood, useBlockbuster].filter(v => v).length;
    //
    // const sections: Array<Section> = [
    //     {
    //         title: 'Gameplay',
    //         options: [
    //             {title: 'Allow In-Game Hints', state: allowHints, toggle: setAllowHints},
    //             {title: 'Allow Movie in a Series', state: allowMovieSeries, toggle: setAllowMovieSeries},
    //             {title: 'Allow TV Shows', state: allowTVSeries, toggle: setAllowTVSeries},
    //             {title: 'Disable Profile Pictures', state: disableProfile, toggle: setDisableProfile},
    //         ]
    //     },
    //     {
    //         title: 'Choose For Me',
    //         options: [
    //             {
    //                 title: 'Use default actors',
    //                 state: useStandard,
    //                 toggle: setUseStandard,
    //                 disabled: useStandard && numSelected === 1
    //             },
    //             {
    //                 title: 'Use expanded actors',
    //                 state: useExpanded,
    //                 toggle: setUseExpanded,
    //                 disabled: useExpanded && numSelected === 1
    //             },
    //             {
    //                 title: 'Use Bollywood actors',
    //                 state: useBollywood,
    //                 toggle: setUseBollywood,
    //                 disabled: useBollywood && numSelected === 1
    //             },
    //             {
    //                 title: 'Use pre-blockbuster actors',
    //                 state: useBlockbuster,
    //                 toggle: setUseBlockbuster,
    //                 disabled: useBlockbuster && numSelected === 1
    //             }
    //         ]
    //     }
    // ];

    return (
        <>
            <div className="modal-title playfair">Options</div>
            <div style={{flexGrow: 1}}/>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch',
                justifyContent: 'flex-start',
                padding: "16px 30px 32px",
                marginBottom: '-24px'
            }}>
                {props.mobile ? (<div key='language' style={{marginBottom: 24, flexGrow: 1}}>
                    <LanguageButton mobile={false} current={LANGUAGE_NAMES[props.language]} onClick={() => setSelectLanguage(!selectLanguage)}/>
                    <Popper anchor="language-button" visible={selectLanguage} content={(<>
                        {Object.entries(LANGUAGE_NAMES).map(([key, value]) => {
                            return (
                                <button
                                    key={key}
                                    onClick={() => {
                                        props.setLanguage(key);
                                        setSelectLanguage(false);
                                    }}
                                    disabled={props.language === key}
                                >{value}</button>
                            );
                        })}
                    </>)}/>
                </div>) : null}
                {sections.map(s => {
                    return (<div key={s.title} style={{marginBottom: 24, flexGrow: 1}}>
                        <p className="modal-heading akkurat">- {s.title} -</p>
                        {s.options.map(v => (
                            <div key={v.title} style={{display: 'flex', flexDirection: 'row', gap: '12px'}}>
                                <div style={{textAlign: "start", justifySelf: 'flex-start', flexGrow: 1}}>
                                    <span className="modal-body akkurat">{v.title}</span>
                                </div>
                                <div style={{justifySelf: 'flex-end'}}>
                                    <Switch
                                        checked={v.state}
                                        onChange={c => v.toggle(c)}
                                        disabled={v.disabled}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>);
                })}
            </div>
            <div style={{flexGrow: 1}}/>
        </>
    );
}

export default OptionsModal;
